#secaoDashboard {
    margin-top: -15px !important;
    margin-left: -17px !important;
    margin-right: 0px !important;
    margin-bottom: 0px !important;
    width: calc(100% + 34px) !important;
}

#secaoDashboard > #iframeDashboard {
    height: calc(100vh - 48px) !important;
}